<template>
  <div class="full-page checkout-container">
    <div class="section-summary-sticky">
      <CheckoutSummaryStickyAccordion
        isShowLabelExpand
        class="dp-block dp-lg-none"
      >
        <template v-slot:title>
          <div class="title-semibold">
            {{ finalPrice | currency2 }}
          </div>
        </template>
        <template v-slot:content>
          <div class="section-summary-sticky-accordion">
            <CheckoutProductItems
              :data="[
                {
                  imageUrl: data.product_main.image_url,
                  name: data.product_main.name,
                  price_ship: data.product_main.price,
                },
                ...bundles,
              ]"
            />
            <hr class="divider-gray-83" />

            <CheckoutSummary
              :cartSummaryData="cartSummaryData"
              :additionalDiscountLabel="additionalDiscountLabel"
              :isDeposit="isDeposit"
              :isPickupAtStore="!isDelivery"
              :shipDate="shippingDate"
              :branchName="receiveBranchName"
            />
          </div>
        </template>
      </CheckoutSummaryStickyAccordion>
    </div>

    <div class="left-container">
      <div class="section-information">
        <OrderDetailHeader
          v-if="data.length === 0"
          :image="require('@/assets/icon/fail.svg')"
          :order="data.order"
          title="ไม่พบเลขที่ใบจองหรือทำรายการไม่สำเร็จ"
        />

        <template
          v-if="
            data.length !== 0 && data.status === ORDER_STATUS.COMPLETE
          "
        >
          <OrderDetailHeader
            v-if="data.payment_is_deposit"
            :image="
              require('@/assets/icon/studio7/icon-check-circle.svg')
            "
            :order="data.order"
            :title="`ขอบคุณ, คุณ ${data.full_name}`"
          />
          <OrderDetailHeader
            v-else
            :image="
              require('@/assets/icon/studio7/icon-check-circle.svg')
            "
            :order="data.order"
            :title="`ขอบคุณ, คุณ ${data.full_name}`"
          />
        </template>

        <OrderDetailHeader
          v-if="data.length !== 0 && data.status === ORDER_STATUS.OK"
          :image="
            require('@/assets/icon/studio7/icon-check-circle.svg')
          "
          :order="data.order"
          title="ชำระเงินมัดจำสำเร็จ"
        />

        <OrderDetailHeader
          v-if="
            data.length !== 0 && data.status === ORDER_STATUS.CREATE
          "
          :image="require('@/assets/icon/process.svg')"
          :order="data.order"
          title="กำลังดำเนินการ"
        />

        <template
          v-if="
            data.length !== 0 && data.status === ORDER_STATUS.PROCESS
          "
        >
          <OrderDetailHeader
            v-if="data.is_pay_at_store"
            :image="require('@/assets/icon/process.svg')"
            :order="data.order"
            title="รอชำระเงิน"
          />
          <OrderDetailHeader
            v-else
            :image="require('@/assets/icon/process.svg')"
            :order="data.order"
            title="กำลังดำเนินการ"
          />

          <FlashMessagePayAtStore
            v-if="data.is_pay_at_store"
            :image="require('@/assets/icon/alarm.svg')"
            :refId="data.ref_id || data.order"
            :showRefId="true"
          />
        </template>

        <template
          v-if="
            data.length !== 0 && data.status === ORDER_STATUS.SUCCESS
          "
        >
          <OrderDetailHeader
            v-if="
              data.itec_sale_order_status === ORDER_STATUS.OK &&
                data.payment_is_deposit
            "
            :image="require('@/assets/icon/process.svg')"
            :order="data.order"
            title="กำลังดำเนินการ"
          />
          <OrderDetailHeader
            v-else-if="
              data.itec_sale_order_status === ORDER_STATUS.OK &&
                data.status_2nd === ORDER_STATUS.COMPLETE
            "
            :image="
              require('@/assets/icon/studio7/icon-check-circle.svg')
            "
            :order="data.order"
            :title="`ขอบคุณ, คุณ ${data.full_name}`"
          />
          <OrderDetailHeader
            v-else
            :image="
              require('@/assets/icon/studio7/icon-check-circle.svg')
            "
            :order="data.order"
            :title="`ขอบคุณ, คุณ ${data.full_name}`"
          />
        </template>

        <OrderDetailHeader
          v-if="
            data.length !== 0 && data.status === ORDER_STATUS.CANCEL
          "
          :image="require('@/assets/icon/fail.svg')"
          :order="data.order"
          title="ระบบทำการยกเลิกรายการแล้ว"
        />

        <div class="order-detail-container">
          <div class="order-detail-header span-2">
            <div class="headline-m">รายละเอียดคำสั่งซื้อ</div>
          </div>

          <div
            v-if="
              $slots.appendOrderDetail &&
                data.status === ORDER_STATUS.COMPLETE
            "
            class="span-2"
          >
            <slot name="appendOrderDetail" />
          </div>

          <div
            v-if="isDeposit && data.status === ORDER_STATUS.COMPLETE"
            class="span-2"
          >
            <div class="body-s text-gray-2 mb-3">
              เลขที่ใบรับของ (บิลมัดจำ)
            </div>
            <div class="body-s mb-2">
              {{ depositNumber }}
            </div>
          </div>

          <div>
            <div class="body-s text-gray-2 mb-3">
              ลูกค้า
            </div>
            <div class="body-s mb-2">
              {{ data.full_name }}
            </div>
            <div class="body-s mb-2">
              {{ data.email }}
            </div>
            <div class="body-s">
              {{ data.phone }}
            </div>
          </div>

          <div>
            <div class="body-s text-gray-2 mb-3">
              วิธีการชำระเงิน
            </div>
            <div class="title-medium mb-2">
              {{ finalPrice | currency2 }}
            </div>
            <div class="body-s mb-2">
              {{ data.payment_type }}
            </div>
          </div>

          <template v-if="isDelivery">
            <div>
              <div class="body-s text-gray-2 mb-3">
                ที่อยู่จัดส่ง
              </div>
              <div class="body-s mb-2">
                {{ data.full_name }} ({{ data.phone }})
              </div>
              <div class="body-s">
                {{ fullBillAddress }}
              </div>
            </div>
          </template>

          <template v-else>
            <div>
              <div class="body-s text-gray-2 mb-3">
                รับที่สาขา
              </div>
              <div class="body-s mb-2">
                {{ receiveBranchName }}
              </div>
              <div class="body-s">
                {{ receiveBranchAddress }}
              </div>
            </div>
          </template>

          <div v-if="data.bill_address">
            <div class="body-s text-gray-2 mb-3">
              ที่อยู่ในใบเสร็จ
            </div>
            <div class="body-s mb-2">
              {{ data.bill_address.first_name }}
              {{ data.bill_address.last_name }} ({{
                data.bill_address.phone
              }})
            </div>
            <div class="body-s">
              {{ fullBillAddress }}
            </div>
          </div>

          <div v-if="data.tax_address" class="span-2">
            <div class="body-s text-gray-2 mb-3">
              ที่อยู่ในใบกำกับภาษี
              <template
                v-if="data.tax_address.tax_invoice_type == 'PERSONAL'"
              >
                (ภาษีบุคคล)
              </template>
              <template v-else>
                (นิติบุคคล)
              </template>
            </div>

            <div class="body-s mb-2">
              {{ data.tax_address.tax_id }}
            </div>
            <div class="body-s mb-2">
              <template
                v-if="data.tax_address.tax_invoice_type == 'PERSONAL'"
              >
                {{ data.tax_address.first_name }}
                {{ data.tax_address.last_name }}
              </template>
              <template v-else>
                {{ data.tax_address.company_name }}
                <span v-if="data.tax_address.company_code">
                  ({{ data.tax_address.company_code }})
                </span>
              </template>
              ({{ data.tax_address.phone }})
            </div>
            <div class="body-s">
              {{ fullTaxAddress }}
            </div>
          </div>
        </div>

        <div class="dp-block dp-md-none">
          <div class="section-summary">
            <template v-if="bundles.length == 0">
              <div class="headline-m">
                รายการสั่งซื้อ
              </div>

              <CheckoutProductItems
                :data="[
                  {
                    imageUrl: data.product_main.image_url,
                    name: data.product_main.name,
                    price_ship: data.product_main.price,
                  },
                  ...bundles,
                ]"
                @remove="onRemoveBundleAndAdditional"
                class="mt-4"
              />
            </template>

            <PDPAccordionItem
              v-if="bundles.length > 1"
              isShowLabelExpand
            >
              <template v-slot:title>
                <div class="headline-m">
                  คำสั่งซื้อ ({{ bundles.length + 1 }})
                </div>
              </template>
              <template v-slot:content>
                <CheckoutProductItems
                  :data="[
                    {
                      imageUrl: data.product_main.image_url,
                      name: data.product_main.name,
                      price_ship: data.product_main.price,
                    },
                    ...bundles,
                  ]"
                  class="mt-4"
                />
              </template>
            </PDPAccordionItem>

            <CheckoutSummary
              :cartSummaryData="cartSummaryData"
              :additionalDiscountLabel="additionalDiscountLabel"
              :isDeposit="data.is_deposit"
              :branchName="data.receive_branch_name"
              :shipDate="shippingDate"
              :isPickupAtStore="!isDelivery"
              :isShowMarketingBanner="true"
              class="mt-4"
            />
          </div>
        </div>

        <div class="order-detail-action">
          <div>
            หากพบปัญหา
            <v-btn
              depressed
              class="btn-link"
              text
              :color="colorBnn['color-bnn']"
              @click="contactUs"
            >
              ติดต่อเรา
            </v-btn>
          </div>

          <v-btn
            depressed
            :dark="true"
            :color="colorBnn['color-bnn']"
            :disabled="disabledButton"
            :loading="loading"
            @click="goBack"
          >
            กลับสู่หน้าหลัก
          </v-btn>
        </div>
        <Footer />
      </div>
    </div>
    <div class="right-container">
      <div class="section-summary d-none d-md-flex">
        <CheckoutProductItems
          :data="[
            {
              imageUrl: data.product_main.image_url,
              name: data.product_main.name,
              price_ship: data.product_main.price,
            },
            ...bundles,
          ]"
        />

        <hr class="divider-gray-83" />

        <CheckoutSummary
          :cartSummaryData="cartSummaryData"
          :additionalDiscountLabel="additionalDiscountLabel"
          :isDeposit="data.is_deposit"
          :branchName="data.receive_branch_name"
          :shipDate="shippingDate"
          :isPickupAtStore="!isDelivery"
          :isShowMarketingBanner="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import isBnn from '@/config/isBnn'
import colorBnn from '@/config/color'
import cartSummary from '@/mixins/cartSummary'
import { ORDER_STATUS } from '@/config/order'

export default {
  name: 'OrderDetailStudio7',

  mixins: [cartSummary],

  components: {
    // CheckoutBreadcrumb: () =>
    //   import('@/components/studio7/CheckoutBreadcrumb'),
    // CheckoutCustomerForm: () =>
    //   import('@/components/studio7/CheckoutCustomerForm'),
    CheckoutProductItems: () =>
      import('@/components/studio7/CheckoutProductItems'),
    CheckoutSummary: () =>
      import('@/components/studio7/CheckoutSummary'),
    PDPAccordionItem: () =>
      import('@/components/studio7/PDPAccordionItem'),

    Footer: () => import('@/components/bnn/Footer'),

    OrderDetailHeader: () =>
      import('@/components/studio7/OrderDetailHeader'),

    CheckoutSummaryStickyAccordion: () =>
      import('@/components/studio7/CheckoutSummaryStickyAccordion'),

    FlashMessagePayAtStore: () =>
      import('@/components/bnn/FlashMessagePayAtStore'),
  },

  data() {
    return {
      // Import
      colorBnn,
      isBnn,
      ORDER_STATUS,

      // Data
      form: {
        couponCode: '',
      },
      formCheckSummary: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        address: null,
        sub_district: null,
        district: null,
        province: null,
        postcode: null,
        // Notes: Hide default value from local storage
        // first_name: localStorage.getItem('firstName') || null,
        // last_name: localStorage.getItem('lastName') || null,
        // phone: localStorage.getItem('phoneNumber') || null,
        // email: localStorage.getItem('email') || null,
        // address: localStorage.getItem('address') || null,
        // sub_district: localStorage.getItem('sub_district') || null,
        // district: localStorage.getItem('district') || null,
        // province: localStorage.getItem('province') || null,
        // postcode: localStorage.getItem('postcode') || null,
        product_id: '',
        bundle_products: [],
        delivery: false,
        is_delivery: false,
      },
      freebieListByCouponModal: false,
    }
  },

  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      bundleList: state => state.model.bundleList,
      campaign: state => state.campaign.current,

      data: state => state.order.data,
    }),

    isDelivery() {
      return this.data?.is_delivery
    },

    isDeposit() {
      return this.data?.is_deposit
    },

    isPayAtStore() {
      return this.data?.is_pay_at_store
    },

    isPayAtStoreTradeIn() {
      return (
        this.data?.pay_at_store_detail?.order_type == 'with_tradein'
      )
    },

    isPayAtStorePromotion() {
      return this.data?.pay_at_store_detail?.order_type == 'with_plan'
    },

    ...mapGetters({
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      cartItems: 'cart/cartItems',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      bundleItems: 'cart/bundleItems',
      discountOnTop: 'cart/discountOnTop',
      deliveryItems: 'cart/deliveryItems',
      getBundleDelivery: 'model/getBundleDelivery',
      accessoryBundles: 'model/getAccessoryBundles',
      // getFreebieHandraiser
      handraiserFreebies: 'model/getHandraiserFreebies' ?? [],
    }),

    finalPrice() {
      return (
        this.data?.pay_at_store_detail?.paid_amount ||
        this.data?.total_price ||
        0
      )
    },

    additionalDiscountLabel() {
      return this.isPayAtStoreTradeIn
        ? 'ส่วนลดจาก Trade-in'
        : this.isPayAtStorePromotion
        ? 'ส่วนลดจากโปรโมชั่นเบอร์ทรู'
        : 'ส่วนลดเพิ่มเติม'
    },

    cartSummaryData() {
      return {
        before_discount: this.data?.before_discount,
        summary_discount: Number(this.data?.discount),
        product_price_deposit_summary: this.data?.deposit,
        remain_amount_original: this.data?.remaining,
        final_price: this.finalPrice,

        // Additional discount
        additional_discount: this.isPayAtStoreTradeIn
          ? Number(this.data?.pay_at_store_detail?.voucher_amount)
          : this.isPayAtStorePromotion
          ? //   ? this.data?.pay_at_store_detail?.promotion_discount
            this.data?.pay_at_store_detail
              ?.with_plan_promotion_discount
          : 0,

        advance_payment: this.data?.pay_at_store_detail
          ?.with_plan_advance_payment,
        advance_payment_optional: this.data?.pay_at_store_detail
          ?.with_plan_advance_payment_optional,
      }
    },

    bundles() {
      return (
        this.data?.bundle_products?.filter(
          i => i?.type !== 'delivery',
        ) ?? []
      )
    },

    receiveBranchName() {
      return this.data?.receive_branch_name ?? ''
    },

    receiveBranchAddress() {
      return this.data?.receive_branch_address
        ? `${this.data?.receive_branch_address?.address} ${this.data?.receive_branch_address?.province} `
        : ''
    },

    shippingDate() {
      return (
        this.data?.shipping_date ??
        this.data?.receive_date ??
        'ไม่พบข้อมูล'
      )
    },

    fullAddress() {
      return `เลขที่ ${this.data?.address} แขวง/ตำบล ${this.data?.sub_district} เขต/อำเภอ ${this.data?.district} ${this.data?.province} ${this.data?.postcode}`
    },

    fullTaxAddress() {
      return `เลขที่ ${this.data?.tax_address?.address} แขวง/ตำบล ${this.data?.tax_address?.sub_district} เขต/อำเภอ ${this.data?.tax_address?.district} ${this.data?.tax_address?.province} ${this.data?.tax_address?.postcode}`
    },

    fullBillAddress() {
      return `เลขที่ ${this.data?.bill_address?.address} แขวง/ตำบล ${this.data?.bill_address?.sub_district} เขต/อำเภอ ${this.data?.bill_address?.district} ${this.data?.bill_address?.province} ${this.data?.bill_address?.postcode}`
    },

    depositNumber() {
      return this.data?.deposit_number ?? 'กำลังดำเนินการ'
    },
  },

  async mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch(
        'staticPage/getStaticPage',
        'marketing-banner',
      )
    })
  },

  methods: {
    onRemoveBundleAndAdditional(item) {
      this.$store.dispatch('bundle/toggle', item)
      this.$forceUpdate()
      this.checkValidateCoupon()
      this.checkSummary()
    },

    async checkValidateCoupon() {
      if (this.form.couponCode.trim()) {
        const payload = {
          productId: this.selectSKU.id,
          couponCode: this.form.couponCode,
          bundleItems: this.bundleItems,
          paymentTypeId: this.formCheckSummary.payment_type_id,
        }

        await this.$store.dispatch('cart/checkCouponCode', payload)

        if (this.coupon.autoAddToCart) {
          this.HandleFreebieWithCoupon('add')
        }

        this.checkSummary()
      }
    },

    HandleFreebieWithCoupon(key) {
      if (this.coupon.autoAddToCart) {
        switch (key) {
          case 'remove':
            this.bundleItems.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/remove', item)
              }
            })
            break

          default:
            this.handraiserFreebies?.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/add', item)
              }
            })
            break
        }
      }
    },

    contactUs() {
      // TODO: Redirect to contact page new page
      window.open(
        'https://www.studio7thailand.com/th/pages/contact-us',
        '_blank',
      )
    },

    goBack() {
      let slug =
        this.$route.params?.slug ||
        localStorage.getItem('productLatestVisit')

      this.$router.push({
        name: 'ProductDetail',
        params: {
          slug: slug,
        },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.checkout-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: minmax(min-content, calc(50% + 72px)) 1fr;

        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    > .left-container {
        width 100%
        max-width: 570px;
        background-color: #fff
        border-right: none;
        margin: 0 auto;
        min-height: 100dvh;

        @media screen and (min-width: 1024px) {
            max-width: 100%;
            border-right: 1px solid #dedede;
            margin: 0;
        }

        > .section-information {
            width 100%
            max-width: 100dvw
            padding: 0 21px 0;
            display: flex;
            flex-direction: column;
            gap: 26px;
            margin-top: 24px;

            @media screen and (min-width: 1024px)  {
                margin-top: 60px;
                padding: 0 22px 0;
                max-width: 660px;
                margin-left: auto;
            }
        }
    }

    >.right-container {
        width 100%
        background-color: #fff;

        @media screen and (min-width: 1024px) {
            width 100%
            background-color: $color-grey-light-80;
            min-height: 100dvh;
        }

        >.section-summary {
            width 100%
            max-width: 100dvw
            padding: 21px;

            display flex;
            flex-direction: column;
            gap: 32px;

            position: relative;

            @media screen and (min-width: 1024px) {
                position: sticky;
                top: 72px;

                padding: 38px;
                width 100%
                max-width: 520px;
                margin-right: auto;

                display flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}

.order-detail-header{
    display: flex;
    align-items: center;
    gap: 16px;
}

.order-detail-container {
    border: 1px solid $color-gray85
    border-radius: 8px
    padding: 16px

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .span-2 {
        grid-column: span 1

        @media screen and (min-width: 1024px) {
            grid-column: span 2
        }
    }
}

.order-detail-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.section-summary-sticky {
    background-color: $color-grey-light-80;

    grid-column: span 2;
}

.section-summary-sticky-accordion {
    width 100%
    max-width: 100dvw
    padding: 21px 21px 0;

    display flex;
    flex-direction: column;
    gap: 21px;
}

.full-page
 overflow: visible !important
</style>
