import branchTypes from '@/config/branchTypes'
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      branchType: state => state.branchStore.branchType,
      coupon: state => state.cart.coupon,
      cartDelivery: state => state.cart.delivery,
      cartSummaryData: state => state.cart.cartSummaryData,
      paymentId: state => state.paymentType.paymentId,
    }),

    ...mapGetters({
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      accessoryBundles: 'model/getAccessoryBundles',
      cartItems: 'cart/cartItems',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      bundleItems: 'cart/bundleItems',
      discountOnTop: 'cart/discountOnTop',
      deliveryItems: 'cart/deliveryItems',
    }),

    isDelivery() {
      return (
        this.cartDelivery === 'delivery' || this.cartDelivery === true
      )
    },

    isStore() {
      return this.cartDelivery === 'store'
    },
  },

  data() {
    return {
      formCheckSummary: {
        // first_name: localStorage.getItem('firstName') || 'first_name',
        // last_name: localStorage.getItem('lastName') || 'last_name',
        // phone: localStorage.getItem('phoneNumber') || 'phone',
        // email: localStorage.getItem('email') || 'email',
        // address: localStorage.getItem('address') || 'address',
        // sub_district:
        //   localStorage.getItem('sub_district') || 'sub_district',
        // district: localStorage.getItem('district') || 'district',
        // province: localStorage.getItem('province') || 'province',
        // postcode: localStorage.getItem('postcode') || 'postcode',
        product_id: '',
        bundle_products: [],
        delivery: false,
        is_delivery: false,
      },
      branchTypes: branchTypes,
    }
  },

  methods: {
    async checkSummary() {
      this.prepare()
      await this.$store.dispatch(
        'cart/cartSummary',
        this.formCheckSummary,
      )
      this.$forceUpdate()
    },

    prepare() {
      let coupon_code = undefined
      coupon_code =
        this.coupon.code && !this.coupon.isError
          ? this.coupon.code
          : undefined
      // if (this.branchType !== this.branchTypes.BRANCH_TYPE_DEPOSIT) {
      //   coupon_code =
      //     this.coupon.code && !this.coupon.isError
      //       ? this.coupon.code
      //       : undefined
      // }

      let payment_type_id = this.paymentId ?? undefined

      let bundleSelect = []
      this.bundles?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b.sku === i.sku)) {
          has = true
        }
        if (has) {
          bundleSelect.push(
            this.bundleItems.filter(b => b.sku === i.sku)?.[0],
          )
        }
      })

      let additionProductSelect = []
      this.additionalProduct?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b?.sku === i?.sku)) {
          has = true
        }
        if (has) {
          additionProductSelect.push(
            this.bundleItems.filter(b => b?.sku === i?.sku)?.[0],
          )
        }
      })

      let appleCareSelect = []
      let has = false
      if (
        this.bundleItems.find(b => b?.sku === this.appleCare?.sku)
      ) {
        has = true
      }

      if (has) {
        appleCareSelect.push(
          this.bundleItems.filter(
            b => b?.sku === this.appleCare?.sku,
          )?.[0],
        )
      }

      let accessoryBundlesSelect = []
      this.accessoryBundles?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b?.sku === i?.sku)) {
          has = true
        }
        if (has) {
          accessoryBundlesSelect.push(
            this.bundleItems.filter(b => b?.sku === i?.sku)?.[0],
          )
        }
      })

      this.formCheckSummary.product_id = this.productItem.id
      this.formCheckSummary.bundle_products = [
        ...bundleSelect,
        ...additionProductSelect,
        ...appleCareSelect,
        ...accessoryBundlesSelect,
        ...this.deliveryItems,
      ]

      this.formCheckSummary.coupon_code = coupon_code
      this.formCheckSummary.payment_type_id = payment_type_id
      this.formCheckSummary.delivery =
        !this.isStore && !this.isDelivery ? undefined : !this.isStore
      this.formCheckSummary.is_delivery = !this.isStore
    },
  },
}
